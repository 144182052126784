mcWeb.freeShippingTxt = new (function ($) {
    'use strict';

    this.load = function () {
        var self = this;
        self.service = new WebApiService("Cart");

        self.updateFreeShippingTxt = function () {
            var data = { isCheckout: (($('.body-checkout').length > 0) ? true : false) };

            self.service.GetAction("UpdateFreeShipmentCountDown", data).done(function (result) {
                if (result.length > 0) {
                    $('.free-shipping-counter').html(result);
                }
            });
        };//End updateFreeShippingTxt

        PubSub.subscribe(mcWeb.events.freeShippingTxt.onUpdateFreeShipping, function () {
            self.updateFreeShippingTxt();
        });
    };//End load
})(jQuery);

document.addEventListener('DOMContentLoaded', function () {
    mcWeb.freeShippingTxt.load();
});